import MarsBook from "../components/MarsBook";
import MarsHeader from "../components/MarsHeader";

import Footer from "../components/Footer";
const Mars = () => {
  return (
    <div>
      <MarsHeader />
      <MarsBook />
      <Footer />
    </div>
  );
};

export default Mars;
