import CommisionsBottom from "../components/CommisionsBottom";
import CommisionsComponent from "../components/CommisionsComponent";
import CommisionsHeader from "../components/CommisionsHeader";
import Footer from "../components/Footer";

const Store = () => {
  return (
    <div>
      <CommisionsHeader />
      <CommisionsComponent />
      <CommisionsBottom />
      <Footer />
    </div>
  );
};

export default Store;
