import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import Swal from "sweetalert2";

const ContactComponent = () => {
  const form = useRef();

  const handleSubmit = (event) => {
    document.getElementById("my_form").reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    // handleSubmit();

    emailjs
      .sendForm(
        "service_yhr3dsq",
        "template_nqpssnq",
        form.current,
        "hDxtCg9glxKC3YPut"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: `<h5 style='color:green'>Message Sent Successfully</h5>`,
            confirmButtonColor: "green",
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: `<h5 style='color:red'>Ooops, something went wrong</h5>`,
            confirmButtonColor: "red",
          });
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <section id="contact" className="contact" style={{}}>
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <p style={{ color: "white" }}>Contact Us</p>
          </header>

          <div className="row">
            <div className="mx-auto col-10 col-md-8 col-lg-6">
              <form
                id="my_form"
                ref={form}
                onSubmit={sendEmail}
                className="php-email-form text-center"
                style={{}}
              >
                <div className="row gy-4">
                  <div for="name" className="col-md-6">
                    <input
                      style={{ borderRadius: "1rem" }}
                      type="name"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div for="email" className="col-md-6">
                    <input
                      style={{ borderRadius: "1rem" }}
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div for="subject" className="col-md-12">
                    <input
                      style={{ borderRadius: "1rem" }}
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      required
                      //   style={{ borderColor: "#fafbff" }}
                    />
                  </div>

                  <div for="message" className="col-md-12">
                    <textarea
                      style={{ borderRadius: "1rem" }}
                      className="form-control"
                      id="message"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center ">
                    <button
                      onclick="sendmail()"
                      style={{
                        borderRadius: "1rem",
                        backgroundColor: "black",
                        padding: "7px",
                        color: "white",
                        // lineHeight: "0px",
                        textAlign: "center",
                      }}
                      type="submit"
                      onChange={handleSubmit}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactComponent;
