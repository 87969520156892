import HTMLFlipBook from "react-pageflip";
import React, { useState } from "react";
import amen1 from "../assets/Logo/Comics/Amen/Amen_Cover.jpg";

import Empty from "../components/Empty";
import amen0 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz.jpg";
import amen2 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz2.jpg";
import amen3 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz3.jpg";
import amen4 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz4.jpg";
import amen5 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz5.jpg";
import amen6 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz6.jpg";
import amen7 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz7.jpg";
import amen8 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz8.jpg";
import amen9 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz9.jpg";
import amen10 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz10.jpg";
import amen11 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz11.jpg";
import amen12 from "../assets/Logo/Comics/Amen/Draft6_Omar_Muzquiz12.jpg";
const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <div>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      {/* <h1 className="m-1">Amen</h1> */}
      <p>{props.children}</p>
      <p>{props.number}</p>
    </div>
  );
});

function AmenBook(props) {
  const [inputText, setInputElement] = useState("");
  const [text, setText] = useState("ここに表示されます。");
  const printText = () => {
    setText(inputText);
    setInputElement("");
  };

  return (
    <section style={{ height: "100%" }} bgcolor="LightCyan">
      <div className="p-1">
        <HTMLFlipBook
          width={350}
          height={570}
          minWidth={315}
          maxWidth={1000}
          minHeight={420}
          maxHeight={1350}
          showCover={true}
          flippingTime={1000}
          style={{ margin: "0 auto" }}
          maxShadowOpacity={0.5}
          className="album-web"
          id="album-web-id"
        >
          <PageCover>
            <img src={amen1} id="page-comic" />
          </PageCover>
          <Page number="1">
            <img src={amen0} id="page-comic" />
          </Page>
          <Page number="1">
            <img src={amen2} id="page-comic" />
          </Page>
          <Page number="2">
            <img src={amen3} id="page-comic" />
          </Page>
          <Page number="3">
            <img src={amen4} id="page-comic" />
          </Page>
          <Page number="4">
            <img src={amen5} id="page-comic" />
          </Page>
          <Page number="5">
            <img src={amen6} id="page-comic" />
          </Page>
          <Page number="6">
            <img src={amen7} id="page-comic" />
          </Page>
          <Page number="7">
            <img src={amen8} id="page-comic" />
          </Page>
          <Page number="8">
            <img src={amen9} id="page-comic" />
          </Page>
          <Page number="9">
            <img src={amen10} id="page-comic" />
          </Page>
          <Page number="10">
            <img src={amen11} id="page-comic" />
          </Page>
          <Page number="11">
            <img src={amen12} id="page-comic" />
          </Page>

          <PageCover></PageCover>
        </HTMLFlipBook>
        <br></br>
        <br></br>
      </div>
      <Empty />
    </section>
  );
}

export default AmenBook;
