import AboutComponent from "../components/AboutComponent";
import Empty from "../components/Empty";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
      <AboutComponent />
      <Empty />
      <Footer />
    </div>
  );
};

export default About;
