import PsychoBook from "../components/PsychoBook";
import PsychoHeader from "../components/PsychoHeader";

import Footer from "../components/Footer";
const Psycho = () => {
  return (
    <div>
      <PsychoHeader />
      <PsychoBook />
      <Footer />
    </div>
  );
};

export default Psycho;
