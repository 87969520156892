import React from "react";
import "bootstrap/js/src/collapse.js";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import logo from "../assets/Logo/logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../assets/Logo/logo.png";

function Header({ history }) {
  return (
    <>
      <nav
        id="main-nav"
        className="navbar navbar-light shadow-5-strong navbar-expand-md  justify-content-center"
      >
        <div className="container">
          <a href="/" className="navbar-brand d-flex w-50 me-auto">
            <img id="logo" alt="logo" src={logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsingNavbar3"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse collapse w-100"
            id="collapsingNavbar3"
          >
            <ul className="navbar-nav w-100 justify-content-center">
              <li className="nav-item">
                <Link to="/" className="nav-link" style={{ color: "white" }}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/gallery"
                  className="nav-link"
                  style={{ color: "white" }}
                >
                  Art
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/comics"
                  className="nav-link"
                  style={{ color: "white" }}
                >
                  Comics
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/commissions"
                  className="nav-link"
                  style={{ color: "white" }}
                >
                  Commissions
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav ms-auto w-100 justify-content-end">
              <li className="nav-item">
                <Link
                  type="button"
                  className="btn-contact btn me-2 mt-1 btn-light"
                  style={{ borderRadius: "0.5rem" }}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  type="button"
                  className="btn-contact btn me-2 mt-1 btn-dark"
                  style={{ borderRadius: "0.5rem" }}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
