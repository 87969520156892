import React from "react";
import art57 from "../assets/Logo/Art/2023/COMICI.jpg";
import art104 from "../assets/Logo/Art/2023/Untitled_Artwork6.jpg";
import art59 from "../assets/Logo/Art/2023/Akita.jpg";
import art60 from "../assets/Logo/Art/2023/Sketchs.jpg";
import art62 from "../assets/Logo/Art/2023/War.jpg";
import art63 from "../assets/Logo/Art/2023/Horse.jpg";
import art74 from "../assets/Logo/Art/2023/Free.jpg";
import art75 from "../assets/Logo/Art/2023/Head.jpg";
import art76 from "../assets/Logo/Art/2023/ORCIEN.jpg";
import art77 from "../assets/Logo/Art/2023/Rednblack.jpg";
import art79 from "../assets/Logo/Art/2023/Wavy_Blue.jpg";
import art94 from "../assets/Logo/Art/2023/Ink_toner.jpg";
import art95 from "../assets/Logo/Art/2023/LOVE_HEART.jpg";
import art90 from "../assets/Logo/Art/2023/Grass.jpg";
import art92 from "../assets/Logo/Art/2023/IMG_1568.jpg";
import art93 from "../assets/Logo/Art/2023/IMG_1569.jpg";
import art64 from "../assets/Logo/Art/2023/Blue_Yellow.jpg";
import art96 from "../assets/Logo/Art/2023/Original_.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../assets/Logo/logo.png";
function HomeArtGallery() {
  // const [open, setOpen] = React.useState(false);

  return (
    <>
      <section id="photos-front" className="text-center">
        <a href="/" type="button">
          <LazyLoadImage
            src={art104}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art60}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art62}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art59}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art57}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art74}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art63}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art77}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art75}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art76}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art90}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art64}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art93}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art95}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a className="hidden" href="/" type="button">
          <LazyLoadImage
            src={art96}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <div>
          {/* <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={[
                {
                  src: art57,
                  alt: "image 1",
                  width: 3840,
                  height: 2560,
                  srcSet: [{ src: art58, width: 320, height: 213 }],
                },
              ]}
            /> */}
        </div>
      </section>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-black"></div>
        </div>
      </div>
    </>
  );
}

export default HomeArtGallery;
