import React from "react";
// import { ArrowRight } from "react-bootstrap-icons";

function PsychoHeader() {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row justify-content-md-center mt-0">
            <div className="text-center text-black p-4">
              <a
                className="display-4 fw-bold text-white"
                style={{ overflowY: "hidden" }}
                href="/comics"
              >
                <span>Mars The Wizard</span>
                {/* <ArrowRight style={{ marginLeft: "0.2em" }} size={20} /> */}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PsychoHeader;
