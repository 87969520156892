import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../assets/Logo/logo.png";
// import Lightbox from "yet-another-react-lightbox";
// Comics
// Amen
import amen1 from "../assets/Logo/Comics/Amen/Amen_Cover.jpg";
import amen2 from "../assets/Logo/Comics/Amen2/V2-1.jpg";

// Psycho World
import psycho1 from "../assets/Logo/Comics/Psycho World/Psycho_World_Cover.jpg";
import mars1 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-01.png";

function ComicsFront() {
  // const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className=" text-center" id="parent">
        <div id="photo" className="text-center">
          <a href="/amenchapter2">
            <LazyLoadImage
              src={amen2}
              id="comic-img"
              // loading="lazy"

              PlaceholderSrc={PlaceholderImage}
              effect="blur"
              alt=""
            />
          </a>
          <div className="p-2">
            <h2 className="p-2 text-white">Amen Chapter 2</h2>
          </div>
        </div>
        <div id="photo" className="text-center">
          <a href="/marsthewizard">
            <LazyLoadImage
              src={mars1}
              id="comic-img"
              // loading="lazy"

              PlaceholderSrc={PlaceholderImage}
              effect="blur"
              alt=""
            />
          </a>
          <div className="p-2">
            <h2 className="p-2 text-white">Mars The Wizard</h2>
          </div>
        </div>
        <div id="photo" className="text-center">
          <a href="/amen">
            <LazyLoadImage
              src={amen1}
              id="comic-img"
              // loading="lazy"
              // style={{ width: "100%" }}

              PlaceholderSrc={PlaceholderImage}
              effect="blur"
              alt=""
            />
          </a>
          <div className="p-2">
            <h2 className="p-2 text-white">Amen</h2>
          </div>
        </div>
        <div id="photo" className="text-center">
          <a href="/psycho">
            <LazyLoadImage
              src={psycho1}
              id="comic-img"
              // loading="lazy"

              PlaceholderSrc={PlaceholderImage}
              effect="blur"
              alt=""
            />
          </a>
          <div className="p-2">
            <h2 className="p-2 text-white">Psycho World</h2>
          </div>
        </div>
      </div>
      <section id="photos-comic" className="text-center">
        {/* <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[
              {
                src: amen1,
                alt: "image 1",
                width: 3840,
                height: 2560,
                srcSet: [{ src: [images], width: 320, height: 213 }],
              },
            ]}
          /> */}
      </section>
    </>
  );
}

export default ComicsFront;
