import React from "react";
// import Header from "../components/Header";
// import Hero from "../components/Hero";
// import Gallery from "../components/Gallery";
// import HeadArt from "../components/HeadArt";
// import HeadComic from "../components/HeadComic";
// import ComicsFront from "../components/ComicsFront";

import Hero from "../components/Hero";
import HomeArtGallery from "../components/HomeArtGallery";
import HeadComic from "../components/HeadComic";
import ComicsFront from "../components/ComicsFront";
import Footer from "../components/Footer";
// import Services from "../component/services";
// import Pricing from "../component/Pricing";
// import Portfolio from "../component/Portfolio";
// import Footer from "../components/Footers";

const Home = () => {
  return (
    <div>
      <Hero />
      <HomeArtGallery />
      <HeadComic />
      <ComicsFront />
      <Footer />
    </div>
  );
};

export default Home;
