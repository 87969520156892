import AmenBook from "../components/AmenBook";
import AmenHeader from "../components/AmenHeader";

import Footer from "../components/Footer";
const Amen = () => {
  return (
    <div>
      <AmenHeader />
      <AmenBook />
      <Footer />
    </div>
  );
};

export default Amen;
