import ContactComponent from "../components/ContactComponent";
import Empty from "../components/Empty";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div>
      <ContactComponent />
      <Empty />
      <Footer />
    </div>
  );
};

export default Contact;
