import HTMLFlipBook from "react-pageflip";
import React, { useState } from "react";
// import Footer from "../components/Footer";
import Empty from "../components/Empty";

// Psycho World
import mars1 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-01.png";
import mars2 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-02.png";
import mars3 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-03.png";
import mars4 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-04.png";
import mars5 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-05.png";
import mars6 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-06.png";
import mars7 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-07.png";
import mars8 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-08.png";
import mars9 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-09.png";
import mars10 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-10.png";
import mars11 from "../assets/Logo/Comics/Mars The Wizard/marsthewizard-11.png";

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <div>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      {/* <h1 className="m-1">Amen</h1> */}
      <p>{props.children}</p>
      <p>{props.number}</p>
    </div>
  );
});

function PsychoBook(props) {
  const [inputText, setInputElement] = useState("");
  const [text, setText] = useState("ここに表示されます。");
  const printText = () => {
    setText(inputText);
    setInputElement("");
  };

  return (
    <section style={{ height: "100%" }}>
      <div className="p-1">
        <HTMLFlipBook
          width={350}
          height={570}
          minWidth={315}
          maxWidth={1000}
          minHeight={420}
          maxHeight={1350}
          showCover={true}
          flippingTime={1000}
          style={{ margin: "0 auto" }}
          maxShadowOpacity={0.5}
          className="album-web"
          id="album-web-id"
        >
          <PageCover>
            <img src={mars1} id="page-comic" alt="" />
          </PageCover>
          <Page number="1">
            <img src={mars2} id="page-comic" alt="" />
          </Page>
          <Page number="1">
            <img src={mars3} id="page-comic" alt="" />
          </Page>

          <Page number="2">
            <img src={mars4} id="page-comic" alt="" />
          </Page>
          <Page number="3">
            <img src={mars5} id="page-comic" alt="" />
          </Page>
          <Page number="4">
            <img src={mars6} id="page-comic" alt="" />
          </Page>
          <Page number="5">
            <img src={mars7} id="page-comic" alt="" />
          </Page>
          <Page number="6">
            <img src={mars8} id="page-comic" alt="" />
          </Page>
          <Page number="7">
            <img src={mars9} id="page-comic" alt="" />
          </Page>
          <Page number="8">
            <img src={mars10} id="page-comic" alt="" />
          </Page>
          <Page number="9">
            <img src={mars11} id="page-comic" alt="" />
          </Page>
          <PageCover></PageCover>
        </HTMLFlipBook>
        <br></br>
        <br></br>
      </div>
      <Empty />
    </section>
  );
}

export default PsychoBook;
