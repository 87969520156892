import React from "react";
import { ArrowRight, Bag, Droplet } from "react-bootstrap-icons";

function Hero() {
  return (
    <>
      <section className="hero-box px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
              <h1
                style={{ overflowY: "hidden" }}
                className="display-3 fw-bold mb-3"
              >
                Tabogoo
              </h1>
              <p className="lead mb-5"></p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <a
                  type="button"
                  href="/commissions"
                  className="btn btn-dark btn-lg px-4 mb-1"
                >
                  <span>Commissions</span>
                  <ArrowRight style={{ marginLeft: "0.2em" }} size={20} />
                </a>
                <a
                  type="button"
                  href="/gallery"
                  className="btn btn-light btn-lg px-4 mb-1"
                >
                  <span>Full Gallery</span>
                  <Droplet style={{ marginLeft: "0.2em" }} size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
