import Footer from "../components/Footer";
import FullGallery from "../components/FullGallery";
import FullGalleryHead from "../components/FullGalleryHead";

const Gallery = () => {
  return (
    <div>
      <FullGalleryHead />
      <FullGallery />
      <Footer />
    </div>
  );
};

export default Gallery;
