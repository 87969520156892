import React from "react";
import { ArrowRight, Bag, Droplet } from "react-bootstrap-icons";
const AboutComponent = () => {
  return (
    <>
      <section className="hero-box px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-7 text-center text-white">
              <h1
                style={{ overflowY: "hidden" }}
                className="display-3 fw-bold mb-3"
              >
                About
              </h1>
              <p className="lead mb-5"></p>
              <div className="fs-2 d-grid  d-sm-flex justify-content-center ">
                <p>
                  Hello. <br /> My name is Omar Muzquiz. <br /> I am An
                  illustrator, animator, and comic artist. <br /> I was raised
                  in Oklahoma City and became enamored with the world of art and
                  story telling at a very young age.
                </p>
              </div>
              <p className="lead mb-5"></p>
              <div className="fs-2 d-grid  d-sm-flex justify-content-center ">
                <p>
                  If you would like to contact me, you can at omuzquiz@gmail.com
                  (mailto:omuzquiz@gmail.com)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutComponent;
