import AmenBook2 from "../components/AmenBook2";
import AmenHeader2 from "../components/AmenHeader2";

import Footer from "../components/Footer";
const Amen2 = () => {
  return (
    <div>
      <AmenHeader2 />
      <AmenBook2 />
      <Footer />
    </div>
  );
};

export default Amen2;
