import React from "react";
import { Twitter } from "react-bootstrap-icons";
import { Instagram, EnvelopeFill, Tiktok } from "react-bootstrap-icons";

const Footer = () => {
  return (
    <div>
      <footer
        className="bg-light text-center text-balck "
        style={{ opacity: "0.7" }}
      >
        <div className="container p-4 text-right" style={{ opacity: "0.5" }}>
          <section className="mb-4">
            <a
              className="btn btn-outline-dark btn-floating m-1"
              href="https://www.twitter.com/omar1265"
              // target="_blank"
              role="button"
            >
              <Twitter size={30} />
            </a>

            <a
              className="btn btn-outline-dark btn-floating m-1"
              href="https://www.instagram.com/tabogoo/"
              // target="_blank"
              role="button"
            >
              <Instagram size={30} />
            </a>
            <a
              className="btn btn-outline-dark btn-floating m-1"
              href="https://www.tiktok.com/@crayolacrayoneat"
              // target="_blank"
              role="button"
            >
              <Tiktok size={30} />
            </a>

            <a
              href="/"
              className="btn btn-outline-dark btn-floating m-1"
              onClick={() => (window.location = "mailto:Omuzquiz@gmail.com")}
              role="button"
            >
              <EnvelopeFill size={30} />
            </a>
          </section>
        </div>
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright:
          <a className="text-black" href="https://Tabogoo.com/">
            Tabogoo.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
