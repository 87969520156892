import ComicHeadComponent from "../components/ComicHeadComponent";
import ComicsFront from "../components/ComicsFront";
import Empty from "../components/Empty";
import Footer from "../components/Footer";
const Comics = () => {
  return (
    <div>
      <ComicHeadComponent />
      <ComicsFront />
      <Empty />
      <Footer />
    </div>
  );
};

export default Comics;
