import HTMLFlipBook from "react-pageflip";
import React, { useState } from "react";
// import Footer from "../components/Footer";
import Empty from "../components/Empty";

// Psycho World
import psycho1 from "../assets/Logo/Comics/Psycho World/Psycho_World_Cover.jpg";
import psycho0 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage.jpg";
import psycho2 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage2.jpg";
import psycho3 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage3.jpg";
import psycho4 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage4.jpg";
import psycho5 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage5.jpg";
import psycho6 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage6.jpg";
import psycho7 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage7.jpg";
import psycho8 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage8.jpg";
import psycho9 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage9.jpg";
import psycho10 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage10.jpg";
import psycho11 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage11.jpg";
import psycho12 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage12.jpg";
import psycho13 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage13.jpg";
import psycho14 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage14.jpg";
import psycho15 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage15.jpg";
import psycho16 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage16.jpg";
import psycho17 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage17.jpg";
import psycho18 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage18.jpg";
import psycho19 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage19.jpg";
import psycho20 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage20.jpg";
import psycho21 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage21.jpg";
import psycho22 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage22.jpg";
import psycho23 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage23.jpg";
import psycho24 from "../assets/Logo/Comics/Psycho World/Draft_Omar_Muzquizsinglepage24.jpg";

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="cover" ref={ref} data-density="hard">
      <div>
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      {/* <h1 className="m-1">Amen</h1> */}
      <p>{props.children}</p>
      <p>{props.number}</p>
    </div>
  );
});

function PsychoBook(props) {
  const [inputText, setInputElement] = useState("");
  const [text, setText] = useState("ここに表示されます。");
  const printText = () => {
    setText(inputText);
    setInputElement("");
  };

  return (
    <section style={{ height: "100%" }}>
      <div className="p-1">
        <HTMLFlipBook
          width={350}
          height={570}
          minWidth={315}
          maxWidth={1000}
          minHeight={420}
          maxHeight={1350}
          showCover={true}
          flippingTime={1000}
          style={{ margin: "0 auto" }}
          maxShadowOpacity={0.5}
          className="album-web"
          id="album-web-id"
        >
          <PageCover>
            <img src={psycho1} id="page-comic" alt="" />
          </PageCover>
          <Page number="1">
            <img src={psycho0} id="page-comic" alt="" />
          </Page>
          <Page number="1">
            <img src={psycho2} id="page-comic" alt="" />
          </Page>

          <Page number="2">
            <img src={psycho3} id="page-comic" alt="" />
          </Page>
          <Page number="3">
            <img src={psycho4} id="page-comic" alt="" />
          </Page>
          <Page number="4">
            <img src={psycho5} id="page-comic" alt="" />
          </Page>
          <Page number="5">
            <img src={psycho6} id="page-comic" alt="" />
          </Page>
          <Page number="6">
            <img src={psycho7} id="page-comic" alt="" />
          </Page>
          <Page number="7">
            <img src={psycho8} id="page-comic" alt="" />
          </Page>
          <Page number="8">
            <img src={psycho9} id="page-comic" alt="" />
          </Page>
          <Page number="9">
            <img src={psycho10} id="page-comic" alt="" />
          </Page>
          <Page number="10">
            <img src={psycho11} id="page-comic" alt="" />
          </Page>
          <Page number="11">
            <img src={psycho12} id="page-comic" />
          </Page>
          <Page number="12">
            <img src={psycho13} id="page-comic" />
          </Page>
          <Page number="13">
            <img src={psycho14} id="page-comic" />
          </Page>
          <Page number="14">
            <img src={psycho15} id="page-comic" />
          </Page>
          <Page number="15">
            <img src={psycho16} id="page-comic" />
          </Page>
          <Page number="16">
            <img src={psycho17} id="page-comic" />
          </Page>
          <Page number="17">
            <img src={psycho18} id="page-comic" />
          </Page>
          <Page number="18">
            <img src={psycho19} id="page-comic" />
          </Page>
          <Page number="19">
            <img src={psycho20} id="page-comic" />
          </Page>
          <Page number="20">
            <img src={psycho21} id="page-comic" />
          </Page>
          <Page number="21">
            <img src={psycho22} id="page-comic" />
          </Page>
          <Page number="22">
            <img src={psycho23} id="page-comic" />
          </Page>
          <Page number="23">
            <img src={psycho24} id="page-comic" />
          </Page>

          <PageCover></PageCover>
        </HTMLFlipBook>
        <br></br>
        <br></br>
      </div>
      <Empty />
    </section>
  );
}

export default PsychoBook;
