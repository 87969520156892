import React from "react";
import pic1 from "../assets/Logo/Art/2023/Tshirt1.jpg";
import pic2 from "../assets/Logo/Art/2023/War.jpg";
import pic3 from "../assets/Logo/Art/2023/Horse.jpg";
import pic4 from "../assets/Logo/Art/2023/t-shirt2real.JPG";
// import pic4 from "../assets/Logo/Art/2023/Document.jpg";
// import { StarFill } from "react-bootstrap-icons";
function CommisionsComponent() {
  return (
    <>
      <section className="">
        <div className="container ">
          <div className="row justify-content-center mb-3">
            <div className="col-md-12 col-xl-10">
              <div className="card shadow-0 border rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                      <div className="bg-image hover-zoom ripple rounded ripple-surface">
                        <img src={pic4} className="w-100 " alt="" />
                        <a href="/">
                          <div class="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgrounColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <h5 className="p-2">T-Shirt Print</h5>
                      <div className="d-flex flex-row"></div>
                      <div className="mt-1 mb-0 text-muted small">
                        <span>100% cotton</span>
                        <span className="text-primary"> • </span>
                        <span>Light weight</span>
                      </div>
                      <div className="mb-2 text-muted small"></div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                      <div className="d-flex flex-row align-items-center mb-1">
                        <h4 className="mb-1 p-2 me-1">$25.00</h4>
                      </div>

                      <div className="d-flex flex-column mt-4">
                        <a
                          className="btn btn-dark btn-sm p-2"
                          target="_blank"
                          type="button"
                          href="https://buy.stripe.com/dR6aGa1Lb3Vwaje6ow
                          "
                        >
                          Buy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-3 ">
            <div className="col-md-12 col-xl-10">
              <div className="card shadow-0 border rounded-3 bg-black">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                      <div className="bg-image hover-zoom ripple rounded ripple-surface">
                        <img src={pic1} className="w-100" alt="" />
                        <a href="/">
                          <div class="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgrounColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <h1 className=" pb-2 text-danger justify-content-center text-center">
                        Out Of Stock
                      </h1>
                      <h5 className="p-0 text-white">Champko T-Shirt Print</h5>
                      <div className="d-flex flex-row"></div>
                      <div className="mt-1 mb-0 text-muted small">
                        <span className="text-white">100% cotton</span>
                        <span className="text-white"> • </span>
                        <span className="text-white">Light weight</span>
                      </div>
                      <div className="mb-2 text-muted small"></div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                      <div className="d-flex flex-row align-items-center mb-1">
                        <h4 className="mb-1 p-2 me-1 text-white">$40.00</h4>
                      </div>

                      <div className="d-flex flex-column mt-4">
                        <a
                          className="btn btn-danger btn-sm p-2 disabled"
                          target="_blank"
                          type="button"
                          href="https://buy.stripe.com/dR6bKeblL0Jk9fa144"
                        >
                          Out Of Stock
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center text-black p-4 mb-2 ">
            <a
              className="display-5 fw-bold text-white "
              style={{ overflowY: "hidden" }}
              href="/commissions"
            >
              <span>Commissions</span>
            </a>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-md-12 col-xl-10">
              <div className="card shadow-0 border rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                      <div className="bg-image hover-zoom ripple rounded ripple-surface">
                        <img src={pic2} className="w-100" alt="" />
                        <a href="/">
                          <div className="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgrounColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <h5 className="p-2">Custom Collage Style Sketch</h5>

                      <div className="mt-1 mb-0 text-muted small">
                        <span>Black & White Sketch</span>
                      </div>
                      <div className="mb-2 text-muted small">
                        <span>$5 extra for color</span>
                      </div>
                      <div className="mb-2 text-muted small">
                        <span>Digital Only</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                      <div className="d-flex flex-row align-items-center mb-1">
                        <h4 className="mb-1 p-2 me-1">$10.00</h4>
                      </div>

                      <div className="d-flex flex-column mt-4">
                        <a
                          className="btn btn-light btn-sm p-2"
                          href="https://buy.stripe.com/fZe8y2gG52Rs4YUfYZ"
                          target="_blank"
                        >
                          Buy - Black & White
                        </a>
                        <a
                          href="https://buy.stripe.com/00g29E61rgIi8b6003"
                          target="_blank"
                          className="btn btn-dark mt-2 btn-sm p-2"
                        >
                          Buy - Color
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-10">
              <div className="card shadow-0 border rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                      <div className="bg-image hover-zoom ripple rounded ripple-surface">
                        <img src={pic3} className="w-100" alt="" />
                        <a href="/">
                          <div className="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgrounColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <h5 className="p-2">Custom Digital Sketch</h5>
                      <div className="mt-1 mb-0 text-muted small">
                        <span>Black & White Sketch</span>
                      </div>
                      <div className="mb-2 text-muted small">
                        <span>$10 extra for color</span>
                        <br />
                        <span>$10 extra for print</span>
                      </div>

                      <div className="mb-2 text-muted small">
                        <span>Digital Only</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                      <div className="d-flex flex-row align-items-center mb-1">
                        <h4 className="mb-1 p-2 me-1">$25.00</h4>
                      </div>

                      <div className="d-flex flex-column mt-4">
                        <a
                          className="btn btn-light btn-sm p-2"
                          target="_blank"
                          type="button"
                          href="https://buy.stripe.com/5kAbKe89z8bM9fa4gi"
                        >
                          Buy - Black & White
                        </a>
                        <a
                          className="btn btn-dark mt-2 btn-sm p-2"
                          target="_blank"
                          type="button"
                          href="https://buy.stripe.com/14kcOi0H7eAa2QM28c"
                        >
                          Buy - Color
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-3 mb-6">
            <div className="col-md-12 col-xl-10">
              <div className="card shadow-0 border rounded-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-3 mb-4 mb-lg-0">
                      <div className="bg-image hover-zoom ripple rounded ripple-surface">
                        <img src={pic2} className="w-100" alt="" />
                        <a href="/">
                          <div className="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgrounColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-6">
                      <h5 className="p-2">Comic Book </h5>
                      <div className="mt-1  mb-0 text-muted small">
                        <span>15 Page Comic Book </span>
                      </div>

                      <div className="mt-1 mb-0 text-muted small">
                        <span>Black & White Sketch</span>
                        <br />
                        <span>$35 extra for color</span>
                      </div>

                      <div className="mb-2 text-muted small">
                        <span>$10 extra for print</span>
                      </div>
                      <div className="mt-1 mb-0 text-muted small">
                        <span>More Pages Avaiable At CheckOut </span>
                        <br />
                      </div>
                      <div className="mt-1 mb-2 text-muted small">
                        <span>Digital Only</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-3 border-sm-start-none border-start">
                      <div className="d-flex flex-row align-items-center mb-1">
                        <h4 className="mb-1 p-2 me-1">$150.00</h4>
                      </div>

                      <div className="d-flex flex-column mt-4">
                        <a
                          className="btn btn-outline-dark btn-sm p-2"
                          href="https://buy.stripe.com/9AQ8y289zeAagHC8wB"
                          target="_blank"
                        >
                          Buy 15 Pages
                        </a>
                        <a
                          className="btn btn-dark mt-2 btn-sm p-2"
                          href="https://buy.stripe.com/28o8y20H71No62YdQW"
                          target="_blank"
                        >
                          Buy 25 Pages
                        </a>
                        <a
                          className="btn btn-dark mt-2 btn-sm p-2"
                          href="https://buy.stripe.com/28oeWqgG577I8b6dQX"
                          target="_blank"
                        >
                          Buy 45 Pages
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CommisionsComponent;
