import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../assets/Logo/logo.png";
// import { Lightbox } from "react-modal-image";
// 2023 Art
import art2024_1 from "../assets/Logo/Art/2023/Run_Run_It's_On_It's_Way.png";
import art2024_2 from "../assets/Logo/Art/2023/Cityofgod.png";
import art1 from "../assets/Logo/Art/2023/Forehead.jpg";
import art2 from "../assets/Logo/Art/2023/Scannable1.jpg";
import art3 from "../assets/Logo/Art/2023/Scannable2.jpg";
import art4 from "../assets/Logo/Art/2023/Scannable3.jpg";
import art5 from "../assets/Logo/Art/2023/Untitled_Artwork.jpg";
import art6 from "../assets/Logo/Art/2023/Untitled_Artwork1.jpg";
import art7 from "../assets/Logo/Art/2023/Untitled_Artwork2.jpg";
import art9 from "../assets/Logo/Art/2023/Untitled_Artwork4.jpg";
import art10 from "../assets/Logo/Art/2023/Untitled_Artwork5.jpg";
import art104 from "../assets/Logo/Art/2023/Untitled_Artwork6.jpg";
import art57 from "../assets/Logo/Art/2023/COMICI.jpg";
import art58 from "../assets/Logo/Art/2023/Untitled_Artwork3.jpg";
import art59 from "../assets/Logo/Art/2023/Akita.jpg";
import art60 from "../assets/Logo/Art/2023/Sketchs.jpg";
import art62 from "../assets/Logo/Art/2023/War.jpg";
import art63 from "../assets/Logo/Art/2023/Horse.jpg";
import art61 from "../assets/Logo/Art/2023/Untitled_Artwork7.jpg";

// 2022 Art
import art64 from "../assets/Logo/Art/2023/Blue_Yellow.jpg";
import art65 from "../assets/Logo/Art/2023/Draft_4-1.jpg";
import art66 from "../assets/Logo/Art/2023/Draft_4-2.jpg";
import art67 from "../assets/Logo/Art/2023/Draft_4-3.jpg";
import art68 from "../assets/Logo/Art/2023/Draft_4-4.jpg";
import art69 from "../assets/Logo/Art/2023/Draft_4-5.jpg";
import art70 from "../assets/Logo/Art/2023/Draft_4-6.jpg";
import art71 from "../assets/Logo/Art/2023/Draft_4-7.jpg";
import art72 from "../assets/Logo/Art/2023/Draft_4-8.jpg";
import art73 from "../assets/Logo/Art/2023/Draft_4-9.jpg";
import art74 from "../assets/Logo/Art/2023/Free.jpg";
import art75 from "../assets/Logo/Art/2023/Head.jpg";
import art76 from "../assets/Logo/Art/2023/ORCIEN.jpg";
import art77 from "../assets/Logo/Art/2023/Rednblack.jpg";
import art79 from "../assets/Logo/Art/2023/Wavy_Blue.jpg";
import art80 from "../assets/Logo/Art/2023/Champion_B_and_W.jpg";
import art81 from "../assets/Logo/Art/2023/Color_Study_2.jpg";

import art82 from "../assets/Logo/Art/2023/Cover_1.jpg";
import art83 from "../assets/Logo/Art/2023/Cover_2.jpg";
import art85 from "../assets/Logo/Art/2023/Document.jpg";

import art89 from "../assets/Logo/Art/2023/Front_.jpg";
import art90 from "../assets/Logo/Art/2023/Grass.jpg";
import art91 from "../assets/Logo/Art/2023/Green_haired_.jpg";
import art92 from "../assets/Logo/Art/2023/IMG_1568.jpg";
import art93 from "../assets/Logo/Art/2023/IMG_1569.jpg";
import art94 from "../assets/Logo/Art/2023/Ink_toner.jpg";
import art95 from "../assets/Logo/Art/2023/LOVE_HEART.jpg";
import art96 from "../assets/Logo/Art/2023/Original_.jpg";
import art97 from "../assets/Logo/Art/2023/Original_Color.jpg";
import art98 from "../assets/Logo/Art/2023/Pink_Robe.jpg";
import art99 from "../assets/Logo/Art/2023/Red_Room_.jpg";
import art100 from "../assets/Logo/Art/2023/saw.jpg";
import art101 from "../assets/Logo/Art/2023/Sculler.jpg";
import art102 from "../assets/Logo/Art/2023/Ship.jpg";
import art103 from "../assets/Logo/Art/2023/Sun_Cover_.jpg";
import art86 from "../assets/Logo/Art/2023/Front_ 1.jpg";
import art87 from "../assets/Logo/Art/2023/Front_ 2.jpg";
import art88 from "../assets/Logo/Art/2023/Front_ 3.jpg";

//  2021 Art
import artNew from "../assets/Logo/Art/2023/1new.png";
import artNew1 from "../assets/Logo/Art/2023/2new.png";
import artNew2 from "../assets/Logo/Art/2023/3new.png";
import artNew3 from "../assets/Logo/Art/2023/4new.png";
import artNew4 from "../assets/Logo/Art/2023/5new.png";
import artNew5 from "../assets/Logo/Art/2023/6new.png";
import artNew6 from "../assets/Logo/Art/2023/7new.png";
import artNew7 from "../assets/Logo/Art/2023/Untitled_Artwork 14.png";
import artNew8 from "../assets/Logo/Art/2023/Untitled_Artwork 15.png";
import artNew9 from "../assets/Logo/Art/2023/Untitled_Artwork 16.png";
function FullGallery() {
  return (
    <>
      {/* art2024_1 */}
      <section id="photos" className="text-center p-3">
        <div className="button">
          <LazyLoadImage
            src={artNew7}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew8}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew9}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="holder">
          <LazyLoadImage
            src={art2024_1}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={art2024_2}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="holder">
          <LazyLoadImage
            src={artNew}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew1}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew2}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew3}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew4}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew5}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={artNew6}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>
        <div className="button">
          <LazyLoadImage
            src={art57}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </div>

        <a href="/" type="button">
          <LazyLoadImage
            src={art104}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art60}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art62}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art63}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art59}
            // loading="lazy"
            PlaceholderSrc={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art61}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art1}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art2}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art3}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art4}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art5}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art58}
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            // loading="lazy"
            alt=""
          />
          {/* <LazyLoadImage
            src={art57}
            PlaceholderImage={PlaceholderImage}
            // loading="lazy"
            effect="blur"
            alt=""
          /> */}
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art6}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art7}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art9}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art10}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art64}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art65}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art66}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art67}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art68}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art69}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art70}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art71}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art72}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art73}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        {/* <a href="/" type="button">
          <LazyLoadImage
            src={art74}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a> */}
        <a href="/" type="button">
          <LazyLoadImage
            src={art74}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art75}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art76}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art77}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art79}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art80}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art81}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art82}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art83}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art85}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <a href="/" type="button">
          <LazyLoadImage
            src={art90}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art91}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art92}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art93}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art94}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art95}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art96}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art97}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art98}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art99}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art100}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art101}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art102}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art103}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art89}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art86}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art87}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>
        <a href="/" type="button">
          <LazyLoadImage
            src={art88}
            // loading="lazy"
            PlaceholderImage={PlaceholderImage}
            effect="blur"
            alt=""
          />
        </a>

        <div></div>
      </section>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-black"></div>
        </div>
      </div>
    </>
  );
}

export default FullGallery;
