import Home from "./page/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header.jsx";
import Contact from "./page/Contact";
import Store from "./page/Store";
import Gallery from "./page/Gallery";
import Comics from "./page/Comics";
// import Font1 from "./Fonts/7183327/a26b2d39-a338-48a3-86d5-f45ae3137765.woff";
// import Font2 from "./Fonts/7183324/0b873ca5-7428-4d20-9e8b-a26689bf6f85.woff";
// import Font3 from "./Fonts/7183324/b57208dc-0d43-44b5-acf1-719464b668c6.woff2";
// import Font4 from "./Fonts/7183327/325222bf-1f28-4396-aee8-bac633081455.woff2";
import Amen from "./page/Amen";
import Amen2 from "./page/Amen2.jsx";
import Psycho from "./page/Psycho";
import About from "./page/About";
import Mars from "./page/Mars";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/comics" element={<Comics />} />
        <Route path="/commissions" element={<Store />} />
        <Route path="/amen" element={<Amen />} />
        <Route path="/psycho" element={<Psycho />} />
        <Route path="/marsthewizard" element={<Mars />} />
        <Route path="/amenchapter2" element={<Amen2 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
